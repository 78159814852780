import React, { useState, useRef } from 'react';
document.title = 'Wiggle Hub';
function Hub() {
  const [passcode, setPasscode] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const correctPasscode = '2866'; // Replace with your actual passcode
  const digitRefs = useRef([]);

  const handlePasscodeSubmit = (event) => {
    event.preventDefault();
    if (passcode === correctPasscode) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect passcode');
    }
  };




  const moveFocus = (index) => {
    if (index < digitRefs.current.length - 1) {
      digitRefs.current[index + 1].focus();
    }
  };

  if (isAuthenticated) {
    return (
      <div className="hub-content">
        <h1>Welcome.</h1>
        <p>UPCOMING TRIP</p>
        <div className="hub-items-container">
          <div className="hub-item">
            <iframe src="https://www.google.com/maps/d/embed?mid=1zg2ewZ9mWTzs23IKP6ctVIBWpPn4Zxs&ehbc=2E312F" width="640" height="480"></iframe>

        </div>

          <div className="hub-item">
          <iframe id='doc-frame' style={{ width: '100%', height: '500px' }} src="https://docs.google.com/document/d/e/2PACX-1vQCqHny9czi8qJwtUgje1s84iqyIbu8R3_-8CDlAOAeGGiK6nxqpkqJmrv9eajSUqu_EXUnODrwlJ87/pub?embedded=true" width="640" height="480"></iframe>

            </div>
        </div>
        
      </div>
    );
  }

  return (
    <div className="passcode-form">
      <form onSubmit={handlePasscodeSubmit}>
        <div className="gate-login-container">
          <h2>Member Authentication</h2>
          <br />
          <p>Please enter the passcode for this content.</p>
          <div className="digit-container">
            <input
              type="text"
              maxLength="4"
              className="digit"
              id="passcode"
              value={passcode}
              onChange={(event) => setPasscode(event.target.value)}
              ref={(ref) => (digitRefs.current[0] = ref)}
            />
                      
                   
          </div>
          <p style={{ fontSize: '12px' }}>
            If you're a member, you'll receive a code from Heidi.
          </p>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default Hub;
