import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Services() {
  const [searchTerm, setSearchTerm] = useState("");

  const services = [
    { name: 'Wiggle Hub', description: 'Specialized tools and content for members.', path: '/hub' },
    { name: 'Game Room', description: 'Mini Games developed by Wiggle Labs', path: '/service1' },
    { name: 'Agrotech.Live', description: 'Environmental analysis & monitoring program.', path: '/service1' }
    // { name: 'stock prediction', description: 'Simple stock prediction/analysis tool.', path: '/service2' },
    // { name: 'wiggleverse', description: 'Explore virtual worlds from the Wiggle Room.', path: '/service2' }
    // Add more services here
  ];

  const filteredServices = services.filter(service =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="main-content">
      <h1>Services</h1>
      <p>
        Seach for a service below.
      </p>
      <input
        type="text"
        placeholder="Search Services"
        onChange={e => setSearchTerm(e.target.value)}
      />
      <ul className='services'>
        {filteredServices.map((service, index) => (
          <li key={index} className='service'>
            <Link to={service.path}>
              <h2>{service.name}</h2>
              <p>{service.description}</p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}


export default Services;
