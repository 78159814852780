import React from 'react';

function Feed() {
  return (
    <div className="main-content">
      <h1>News Feed</h1>
      <p className="default-text">
        <div className='main-content'>
          <div className='card'>
            <h2>📰 Article Title</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sem sapien, dictum ut sagittis non, elementum at sem. Ut eu ex leo.</p>
            <button className='default-button'>Read More</button>
          </div>
          <div className='card'>
          <h2>📻 Activity Title</h2>
            <p>USER started streaming GAME_TITLE on PLATFORM</p>
            <button className='default-button'>Visit Now</button>
          </div>
          <div className='card'>
          <h2>🔵 Notification Title</h2>
            <p>New insights available for SERVICE from BOT.</p>
            <button className='default-button'>View</button>
          </div>
          </div>
      </p>
    </div>
  );
}

export default Feed;
