import React, { Component } from 'react';
import { Chart } from 'react-chartjs-2';

class SensorDataChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sensorData: [],
            normalizedData: {}, // Store normalized data
        };
    }

    componentDidMount() {
        this.fetchSensorData();
    }

    fetchSensorData = async () => {
        try {
            const response = await fetch('http://localhost:5000/sensor-data');
            const data = await response.json();
            const filteredData = data.slice(-12); // Filter data to past 24 hours
            this.normalizeData(filteredData); // Call normalize function here
        } catch (error) {
            console.error('Error fetching sensor data:', error);
        }
    };

    normalizeData = (data) => {
        const oneDayAgo = new Date().getTime() - (24 * 60 * 60 * 1000); // 24 hours ago in milliseconds

    // Filter data to include only the past 24 hours
    const filteredData = data.filter(item => item.Timestamp[0] * 1000 >= oneDayAgo);

        const normalize = (values) => {
            const max = Math.max(...values);
            const min = Math.min(...values);
            return values.map(value => (value - min) / (max - min));
        };

        const temperatureData = normalize(data.map(item => item.Temperature[0]));
        const moistureData = normalize(data.map(item => item.Moisture[0]));
        const lightData = normalize(data.map(item => item.Light[0]));
        const conductivityData = normalize(data.map(item => item.Conductivity[0]));

        this.setState({
            sensorData: data,
            normalizedData: {
                temperatureData,
                moistureData,
                lightData,
                conductivityData
            }
        });
    };

    convertUnixToTime = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const hours = date.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}${ampm}`;
    };

    render() {
        const { normalizedData, sensorData } = this.state;

        const timestamps = sensorData.length > 0 ? sensorData.map((data) => this.convertUnixToTime(data.Timestamp[0])) : [];

        const chartData = {
            labels: timestamps,
            datasets: [
                {
                    label: 'Temperature',
                    data: normalizedData.temperatureData,
                    backgroundColor: '#f00',
                    borderColor: '#f00',
                    borderWidth: 1,
                },
                {
                    label: 'Moisture',
                    data: normalizedData.moistureData,
                    backgroundColor: '#00f',
                    borderColor: '#00f',
                    borderWidth: 1,
                },
                {
                    label: 'Light',
                    data: normalizedData.lightData,
                    backgroundColor: '#ffff00',
                    borderColor: '#ffff00',
                    borderWidth: 1,
                },
                {
                    label: 'Conductivity',
                    data: normalizedData.conductivityData,
                    backgroundColor: '#00ff00',
                    borderColor: '#00ff00',
                    borderWidth: 1,
                },
            ],
        };

        const chartOptions = {
            title: {
                display: true,
                text: 'Sensor Data Visualization',
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }],
            },
            maintainAspectRatio: false, // This allows you to set a custom aspect ratio
            aspectRatio: 100, // Adjust this value to control the height of the chart
        };

        return (
            <div className="sensor-data-chart" style={{ margin: '15px' }}>
                <Chart type="line" data={chartData} options={chartOptions} />
            </div>
        );
    }
}

export default SensorDataChart;
