import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import logo from './images/wr-static-black.png';
import Dashboard from './Dashboard';
import Modal from './Modal';
import Feed from './Feed';
import Services from './Services';
import Discord from './Discord';
import Gate from './Gate';
import Hub from './Hub';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  
  function openNav() {
    setIsOpen(true);
  }

  function closeNav() {
    setIsOpen(false);
  }

  return (
    <Router>
      <div>
        <header>
          <span id="openNav" style={{ fontSize: 30, cursor: "pointer", color: "black"}} onClick={openNav}>
            ☰
          </span>
          <div id="mySidenav" className={`sidenav ${isOpen ? 'open' : ''}`}>
            <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>
              ×
            </a>
            <Link to="/dashboard" onClick={closeNav}>Dashboard</Link>
            <Link to="/news" onClick={closeNav}>Feed</Link>
            <Link to="/services" onClick={closeNav}>Services</Link>
            <Link to="/discord" onClick={closeNav}>Discord</Link>
            <div style={{ fontSize: '12px', padding: '15px'}} className="btilogo">
              <p>© Wiggle Labs 2023</p>
            </div>
          </div>
          <div className="header-img">
      <Link to="/dashboard">
        <img src={logo} alt="Logo" />
      </Link>
          </div>
         
        </header>
        <div id="main" className="main-content">
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/news" element={<Feed />} />
            <Route path="/hub" element={<Hub />} />
            <Route path="/services" element={<Services />} />
            <Route path="/discord" element={<Discord />} />
            <Route path="*" element={<Dashboard />} /> {/* Default Route */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
