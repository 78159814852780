import React, { useEffect, useState } from 'react';
import SensorDataChart from './sensorChart'; // Import SensorDataChart component
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import wrimg from './images/wiggl_gif.gif';
import './App.css';

function Dashboard() {
  const [data, setData] = useState([]);
  const [news, setNews] = useState([]);
  const [sensorData, setSensorData] = useState([]);

  // Function to fetch sensor data
  const fetchSensorData = async () => {
    try {
      const response = await fetch('http://localhost:5000/sensor-data');
      const data = await response.json();
      setSensorData(data);
    } catch (error) {
      console.error('Error fetching sensor data:', error);
    }
  };

  const fetchData = async () => {
    const response = await fetch('http://localhost:5000/api/processes');
    const data = await response.json();
    return data;
  };

  const fetchNews = async () => {
    try {
        const response = await fetch('http://localhost:5000/news-1');
        const newsData = await response.json();
        console.log(newsData); // Check the structure of the response
        setNews(newsData);
    } catch (error) {
        console.error('Error fetching news:', error);
    }
};

  useEffect(() => {
    document.title = 'Wiggle HQ';
    fetchData().then(fetchedData => {
      setData(fetchedData);
    });
    fetchNews();
    fetchSensorData();
  }, []);

  return (
    <div className="dashboard-main">
      <div className="dashboard-header">
        <img className="front-logo-img" src={wrimg} alt="Logo" />
        <br /><br /><br />
        <div className="dash-cards">
          <div className='dash-card'>
            <h2>News</h2>
            {news.map((article, index) => (
              <div key={index}>
                
                <p style={{ textAlign: 'left' }}>
                  
                  <a style={{ textAlign: 'left', textDecoration: 'none', color: 'black'}} href={article.link} target="_blank" rel="noopener noreferrer"><h3 style={{ textAlign: 'left' }}>{article.title}</h3></a>

                  {article.contentSnippet}
                  <br></br>
                  <small><a style={{ justify: 'right' }}  href={article.link} target="_blank" rel="noopener noreferrer">Read more</a></small></p>

              </div>
            ))}
          </div>
          <div className='dash-card'>
          <h2>Lab Activity</h2>
          <div className="chart-container">
            <SensorDataChart />
          </div>
          {sensorData.slice(0, 15).map((data, index) => (
            <div key={index}>
              <p>TS: {new Date(data.Timestamp * 1000).toLocaleString()} | T: {data.Temperature} | M: {data.Moisture} | L: {data.Light} | C: {data.Conductivity}</p>
            </div>
          ))}
</div>
        </div>
        <p id="dashboard-content" className="default-text">     
        </p>
      </div>
    </div>
  );
}

export default Dashboard;
