import React from 'react';

function Discord() {
  return (
    <div className="main-content">
      <h1>Discord</h1>
      <p className="default-text">
        <div className='join-discord'>
       <button>
        <a href="https://discord.gg/2Y8b8Z2">Visit Discord Server</a>
       </button>
       </div>
      </p>
    </div>
  );
}

export default Discord;
